
.app{
    // Device sizes
    $mobile: 360px;
    $desktop: 1366px;
    $tablet: 768px;
    .header{
        font-family: "Inter", Helvetica, Arial, sans-serif;
        background-color: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
        padding: 15px 30px;
        margin-bottom: 30px;
        img{
            width: 220px;
            height: 70px;
        }
        .title{
            // justify-content: end;
            // display: flex;
            &-big{
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                
            }
            &-small{
                font-size: 10px;
                font-weight: 400;
                letter-spacing: 0.02em;
                
                display: block;
                text-align: left;
            }
        }

        .nav-link{
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            &.active{
                font-weight: 800;
                text-decoration: underline;
            }
            &:hover{
                color: #0099CC;
                text-decoration: none;
            }
        }
    }
    .ken-diagram{
        padding: 30px;
        margin: 90px 0;
        position: relative;
        display: flex;
        justify-content: center;
        .gis-p{
            position: absolute;
            left: -35px;
            top: 40%;
            transform: rotate(90deg);
        }
        .sap-p{
            position: absolute;
            right:-35px;
            top: 40%;
            transform: rotate(90deg);
        }
        .box{
            border: 2px solid #000;
            height: 200px;
            width: 50%;
            z-index: 2;
            position: relative;
            .green-box{
                background-color: rgb(226 239 218 / 50%);
                height: 250px;
                width: 50%;
                display: inline-block;
                position: relative;
                top: -54px;
                z-index: 1;
                p{
                    position: absolute;
                    top: 13px;
                    text-align: center;
                    left: calc((100% - 35px) / 2);
                }
            }
            .blue-box{
                background-color: rgb(221 235 247 / 50%);
                height: 250px;
                width: 50%;
                display: inline-block;
                position: relative;
                p{
                    position: absolute;
                    bottom: -15px;
                    text-align: center;
                    left: calc((100% - 178px) / 2);
                }
            }
            .pink-box{
                background-color: pink;
                position: absolute;
                top: calc((100% - 74px) / 2);
                z-index: 3;
                left: calc((100% - 202px) / 2);
                padding: 5px 30px;
                p{
                    font-size: 14px;
                    text-align: center;
                }
            }
            .total-linked{
                position: absolute;
                left: calc((100% - 102px) / 2);
                bottom: -12px;
                z-index: 3;
            }
        }
    }
    label{
        font-size: 14px;
        font-weight: 400;
        color: #A1A1A1;
    }
    .board{
        text-align: center;
        border: 1px solid #18113E;
        margin: 5px;
        padding: 20px;
        border-radius: 8px;
    }
    .bg-blue{
        background-color: #366092;
        color: #FFF;
    }
    .top-border-blue{
        border-top: 1px solid #366092;
        tbody{
            td, th{
                color: #030303;
                font-family: 'Inter';
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;

                a{
                    color: #030303;
                    text-decoration: none;
                    font-family: 'Inter';
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .filter{
        max-width: 30%;
        border-radius: 11px;
        border: 1px solid #000;
        margin-left: 30px;
        padding: 0;
        .date-of-download{
            text-align: center;
            padding: 33px 10px;
            p{
                font-size: 18px;
                font-weight: 600;
                color: green;
            }
    
        }
        .filter-by-asset{
            .label{
                width: 100%;
                background-color: #366092;
                color: #FFF;
                padding: 5px 10px;
            }
            .item{
                margin: 3px 10px;
                padding: 0 5px;
                background-color: #366092eb;
                color: #FFF;
                cursor: pointer;
                font-size: 14px;
                &:hover{
                    background-color: yellow;
                }
                &.active{
                    background-color: #dfdfdf;
                    color: #969696;
                }
            }
        }
    }
    .asset-table{
        max-width: 100%;
    }

    .overview{
        .card{
            box-shadow: 0px 0px 10px 5px #0000000D;
            border-radius: 12px;
            .card-header{
                background: #2C5585;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                h4{
                    font-family: 'Inter';
                    font-size: 20px;
                    font-weight: 800;
                    line-height: 27.32px;
                    text-align: left;
                    color: #FFF;
                    span{
                        font-weight: 400;
                    }

                }
            }
            .card-body{
                .note-board{
                    padding: 5px;

                    p{
                        margin-bottom: 5px;
                        font-size: 14px;
                        color: #D9D9D9;
                        text-align: left;

                    }
                    textarea{
                        height: 75px;
                        font-size: 12px;
                        color: #18113E;
                        width: 100%;
                    }
                    input{
                        height: 75px;
                        font-size: 12px;
                        color: #18113E;
                        width: 100%;
                    }
                }
            }
        }
    }

    .svg--chart{
        height: 70%;
        border: 3px solid #18113E;
        position: relative;
        top: 32px;
        .linked--assets{
            background: #FFFFFF80;
            position: absolute;
            border-radius: 44px;
            z-index: 1;
            width: 266px;
            top: calc((100% - 44px - 20px - 42px)/2);
            left: calc((100% - 266px) / 2);
        }
        .asset--errors{
            position: absolute;
            bottom: calc((100% - 44px - 20px - 42px)/2);
            background: #F6C2CB;
            border: 2px dotted #000000;
            z-index: 1;
            padding: 4px 20px;
            border-radius: 6px;
            left: calc((100% - 192px) / 2);
        }
        .bold{
            font-family: 'Avenir';
            font-weight: 900;
            font-size: 23px;
            line-height: 43.71px;
            letter-spacing: 0%;
            text-align: center;

        }
        .figure{
            font-family: 'Avenir';
            font-weight: 400;
            font-size: 29px;
            line-height: 29px;

        }
        .figure-label{
            font-family: 'Avenir';
            font-weight: 350;
            font-size: 11px;
            line-height: 11.39px;
            letter-spacing: 0%;
            text-transform: uppercase;

        }
        .missing-sap, .missing-gis{
            position: absolute;
            border: 1px dotted #000000;
            width: 100%;
            height: 49px;
            padding: 5px 10px;
            
        }
        .missing-sap{
            top: -50px;
            left: -2px;
            background: #7EC791B2;
        }
        .missing-gis{
            bottom: -50px;
            right: -2px;
            background: #0085CEB2;

        }
        .blue-bg,.green-bg{
            display: inline-block;
            position: relative;
        }
        .green-bg{
            background: #7EC791;
            
        }
        .blue-bg{
            background: #0085CE;
        }
    }
    @media (min-width: $tablet) {
        .navbar-text{
            width: 470px;
        }
    }
    
      // Mobile specific styling
    //   @media (max-width: $tablet) {
    //       width: 85vw;
    //   }
}