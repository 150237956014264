/* Styling for the table */
.table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .table-bordered {
    border: 1px solid #ccc;
  }
  
  .table-bordered td, .table-bordered th {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
  
  /* Blue top border */
  .top-border-blue {
    border-top: 5px solid #007BFF; /* Dark blue top border */
  }
  
  /* Background color for header and header cells */
  .bg-blue {
    background-color: #007BFF; /* Blue header background */
    color: white; /* White text for contrast */
  }
  
  /* Header font weight */
  thead th {
    font-weight: bold;
    text-transform: uppercase;
  }
  
  /* Alternate row background */
  tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Light grey for alternate rows */
  }
  
  /* Hover effect for rows */
  tbody tr:hover {
    background-color: #e0f0ff; /* Light blue on hover */
  }
  
  /* Styling for specific columns */
  th:nth-child(1), td:nth-child(1) { /* First column */
    background-color: #e9ecef; /* Light gray */
    font-weight: bold;
  }
  
  th:nth-child(2), td:nth-child(2) { /* OBJECTID column */
    background-color: #f8f9fa; /* Lighter gray */
  }
  
  td {
    word-wrap: break-word; /* Prevents text overflow */
  }
  
Copy code
/* Button styling */
.button {
  background-color: #007BFF; /* Blue background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 10px 20px; /* Add padding */
  font-size: 16px; /* Increase font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

.button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.button:active {
  background-color: #004080; /* Even darker blue on click */
  transform: scale(0.98); /* Slightly shrink on click */
}

.button:disabled {
  background-color: #cccccc; /* Gray background when disabled */
  cursor: not-allowed; /* Show not-allowed cursor */
}